import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/affilate_mono_repo_vilkencykel/gatsby/src/components/mdxLayout.js";
import { graphql, Link } from "gatsby";
import ProductsDisplay from "../../components/productDisplay.js";
import CategoriesLinks from "../../components/categoriesLinks.js";
export const query = graphql`
  query {
    allMongodbStoreProducts(sort: {fields: popularity_score, order: DESC}, filter: {
        derived_properties: {frame_type: {
          in: ["fatbike"]
        }}
      }) {
      edges {
        node {
          	brand
			full_name
			id
			local_image_name
			model
			original_prices
			popularity_score
			prices
			product_id
			similair_products
			show_on_pages
			sub_product_type
			store
			url
			engine
			last_updated_date
			derived_properties {
				motor_type
				average_range_km
				number_of_gears
				battery_capacity
				frame_type
			}
			fields {
				brand_url
				full_name
				full_name_url
				model_url
			}
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "fatbikes"
    }}>{`Fatbikes`}</h1>
    <p>{`En fatbike är, lite som namnet antyder, en cykel med väldigt tjocka och breda däck. Däcken är ofta så breda som 10-13cm. Vad är då fördelen med en Fatbike kan man fråga sig? Har duy någon gång cyklat i snömodd, sand eller lera så vet du att vanliga däck helt enkelt inte är bra nog, och det är här en Fatbike med riktigt breda däck kommer in i bilden.`}</p>
    <h2 {...{
      "id": "när-behöver-man-en-fatbike"
    }}>{`När behöver man en fatbike?`}</h2>
    <p>{`Huvudegenskapen hos en fatbike är just de breda däcke. Och normalt har man även mycket lägre tryck i däcken jämfört med en traditionell cykel. De breda däcken gör att man får väldigt bra grepp med underlaget. Tillsammans med det låga däcktrycket så får man en cykel som inte så enkelt sjunker ner i underlaget samt en cykel som får väldigt bra grepp.`}</p>
    <p>{`De tjocka däcken med det relativt låga däcktrycket gör även att man sällan behöver en fjädrad ram.`}</p>
    <p>{`Givetvis har en fatbike även nackdelar. De stora och breda däcken gör att man får höga energiförluster i och med friktionen med marken vilket gör att de kan vara tunga att cykla på vanliga vägar jämfört med konventionella cyklar.`}</p>
    <h2 {...{
      "id": "olika-ramar"
    }}>{`Olika ramar`}</h2>
    <p>{`En fatbike har ofta en ram som kan liknas vid en mountainbike, bortsett från fjädringen. Men detta betyder inte att man kan slänga på ett par tjocka "fatbike"-däck på vilken mountainbike eller annan cykel som helst. De breda däcken gör att framgaffeln, och även bakdelen, måste vara extra breda vilket gör att det är väldigt svårt att konvertera en "vanlig" cykel till en fatbike.`}</p>
    <CategoriesLinks mdxType="CategoriesLinks"></CategoriesLinks>
    <ProductsDisplay data={props.data.allMongodbStoreProducts} location={props.location} mdxType="ProductsDisplay"></ProductsDisplay>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      